.full-height-graph-box {
    height: 300px;
    margin-top: 20px
}

.half-height-graph-box {
    height: 150px;
    margin-top: 20px
}

.recharts-tooltip-wrapper {
    z-index: 1000;
}


div.custom-tooltip {
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(204, 204, 204);
    padding: 10px;
    white-space: nowrap;
}

.tooltip-row {
    padding-top: 0.5rem
}
