td.centered {
    text-align: center;
}

.last_date {
    width: 170px;
}

.alert_ready {
    color: #27b600;
    font-weight: bold;
}

.alert_alerted {
    color: #b00101;
    font-weight: bold;
}

.alert_outdated {
    color: #a8a8a8;
    font-weight: bold;
}

div.box {
    background-color: #f5f5f5;
    box-shadow: none;
    border: 1px solid rgba(10, 10, 10, .2);
}

tr.user_disabled {
    color: red
}

div.level-item.sensor-status {
    display: flex;
    align-items: center;
    column-gap: 0.5em;
}

div.level-item.sensor-status img {
    height: 10px;
}

.device-status {
    display: flex;
    align-items: center;
    column-gap: 0.5em;
}

.device-status img {
    height: 10px;
}

.dashboard {
    display: flex;
    flex-direction: column;
}

.dashboard .text {
    margin-left: 0.5rem;
}

.dashboard img {
    height: 1rem;
}

.dashboard p {
    margin-top: 1.5rem;
}

html {
    min-height: 100vh;
}

body {
    min-height: 100vh;
    line-height: 1.0 !important;
}

.title.is-3 {
    font-size: 1.9rem;
}

div.columns.bottom-border {
    border-bottom: 1px solid rgba(10, 10, 10, 0.2)
}

div.content-wrapper {
    height: calc(100vh - 3.25rem);
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
}

div.container.full-width {
    width: 100%;
}

input[type=checkbox] {
    margin-right: 0.5em;
}

.mysens-label {
    margin-right: 0.5rem;
}